@font-face {
  font-family: Kalameh;
  src: url("KalamehWeb_Regular.woff2") format("woff2"),
    url("KalamehWeb_Regular.woff") format("woff");
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: Kalameh;
  src: url("KalamehWeb_Bold.woff2") format("woff2"), url("KalamehWeb_Bold.woff") format("woff");
  font-weight: 700;
  font-display: block;
}
@font-face {
  font-family: Kalameh;
  src: url("KalamehWeb_thin.woff2") format("woff2"), url("KalamehWeb_thin.woff") format("woff");
  font-weight: 300;
  font-display: block;
}
@font-face {
  font-family: Kalameh;
  src: url("KalamehWeb_Black.woff2") format("woff2"), url("KalamehWeb_Black.woff") format("woff");
  font-weight: 900;
  font-display: block;
}
