/* spinner for first load, it renders when react has not mounted the app inside of the root container */
#root:not(:empty) + .spinner-box {
  display: none;
}

.spinner-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 25vh;
  background-color: white;
}

.spinner {
  width: 6vmax;
  height: 6vmax;
  border-right: 5px solid #02a388;
  border-radius: 100%;
  animation: spinRight 1200ms linear infinite;
}

.spinner:before,
.spinner:after {
  content: "";
  width: 4.5vmax;
  height: 4.5vmax;
  display: block;
  position: absolute;
  top: calc(50% - 2.25vmax);
  left: calc(50% - 2.25vmax);
  border-left: 4px solid #02a388;
  border-radius: 100%;
  animation: spinLeft 1200ms linear infinite;
}

.spinner:after {
  width: 3vmax;
  height: 3vmax;
  top: calc(50% - 1.5vmax);
  left: calc(50% - 1.5vmax);
  border: 0;
  border-right: 3px solid #02a388;
  animation: none;
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
